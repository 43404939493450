import React from 'react'
import { View, StyleSheet } from 'react-native-web'

export default function TextRow({ children }) {
	return (
		<View style={styles.row}>
			{children[0]}
			{<View style={styles.flex} />}
			{children[1]}
		</View>
	)
}

const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		paddingHorizontal: 20,
		paddingVertical: 2,
	},
	flex: {
		flex: 1,
	},
})