import React, { useContext, useState } from 'react'
import { StyleSheet, View, Text, Image, ScrollView } from 'react-native-web'

import Button from '../components/Button'
import Header from '../components/Header'
import TextRow from '../components/TextRow'
import CartItem from '../components/CartItem'
import MyContext from '../components/MyContext'
import constants from '../constants'
import { formatPricing } from '../helpers'
import { useNavigate } from 'react-router-dom'

const { SCREEN_HEIGHT, SCREEN_WIDTH, PRIMARY, IMAGE, PATH } = constants
const f = formatPricing

// IMPORTANT
// must update state here when cart updates
export default function CartPage() {
	const { cart, profile, paymentOptions } = useContext(MyContext)
	const navigate = useNavigate()
	const [_, refresh] = useState(true)

	const isReady = cart.isReady()
	const isEmpty = cart.getTotalQty() == 0
	const isCheckoutReady = profile && paymentOptions

	const totalPrice = cart.getTotalPrice()
	const goBack = () => navigate(PATH.HOME)
	const onCheckout = () => navigate(PATH.CHECKOUT)
	const onDelete = (id) => {
		cart.remove(id)
		refresh(r => !r)
	}
	const renderItem = (id) => CartItem(cart.data[id], cart.info[id], onDelete)

	return (
		<View style={styles.container}>
			<Header title={'Cart'} />

			{!isEmpty && isReady ? <>
				<ScrollView style={styles.scrollview}>
					<Text style={styles.qty}>{`Total quantity: ${cart.getTotalQty()}`}</Text>
					{Object.keys(cart.data).map(renderItem)}
				</ScrollView>

				<View style={styles.footer}>
					<TextRow>
						<Text style={[styles.botTxt]}>Item Price</Text>
						<Text style={[styles.botTxt, styles.primary]}>{`${f(totalPrice)} Ks`}</Text>
					</TextRow>

					<Button title={'Payment method and Address'} onPress={onCheckout} 
					style={styles.botButton} loading={!isCheckoutReady}/>
				</View>
			</> : 
				<View style={styles.empty}>
					<Image source={IMAGE.EMPTY_CART} style={styles.image} resizeMode={'contain'} />
					<Text>{`${'Your cart is empty'}`}</Text>
					<Button title={'Buy'} onPress={goBack} style={styles.botButton} />
				</View>
			}
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		height: SCREEN_HEIGHT,
		width: SCREEN_WIDTH,
		backgroundColor: '#ddd',
	},
	qty: {
		marginBottom: 5,
		color:'#777',
	},
	scrollview: {
		marginTop: 20,
		marginHorizontal: 20,
	},
	footer: {
		backgroundColor: 'white',
		paddingVertical: 15,
		marginTop: 2,
	},
	line: {
		borderBottomWidth: 1,
		marginHorizontal: 10,
		marginVertical: 5,
	},
	botTxt: {
		fontSize: 16,
		fontWeight: '600',
	},
	botButton: {
		marginTop: 10,
		marginHorizontal: 20,
		paddingVertical: 10,
	},
	white: {
		color: 'white',
	},
	primary: {
		color: PRIMARY,
	},

	empty: {
		alignItems: 'stretch',
		marginTop: '10vh',
		textAlign: 'center',
	},
	image: {
		alignSelf: 'center',
		width: '80vw',
		height: '30vh',
	}

})