import React from 'react'
import { StyleSheet, View, Text, TouchableOpacity, Image } from 'react-native-web'

import { formatPricing } from '../helpers'
import constants from '../constants'
import { useNavigate } from 'react-router-dom'

const { SCREEN_HEIGHT, SCREEN_WIDTH, PRIMARY, PATH, IMAGE } = constants
const f = formatPricing


export default function SuccessPage() {
	const navigate = useNavigate()

	const exit = () => {
		navigate(PATH.HOME)
	}

	return (
		<View style={styles.container}>
			<View style={styles.box}>
				<Text style={styles.primary}>Order received</Text>

				<Image source={IMAGE.COMPLETE} style={styles.image} resizeMode={'contain'} />

				<TouchableOpacity style={styles.button} onPress={exit}>
					<Text style={styles.white}>Exit</Text>
				</TouchableOpacity>
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		height: SCREEN_HEIGHT,
		width: SCREEN_WIDTH,
		backgroundColor: '#ddd',
		justifyContent: 'center',
		textAlign: 'center',
	},
	box: {
		backgroundColor: '#fff',
		borderWidth: 1,
		borderRadius: 5,
		marginHorizontal: '10%',
		padding: 20,
		alignItems: 'center',
	},
	primary: {
		color: PRIMARY,
	},
	image: {
		width: '100%',
		height: 150,
		margin: 10,
	},
	button: {
		borderRadius: 5,
		backgroundColor: PRIMARY,
		padding: 8,
		minWidth: '50%'
	},
	white: {
		color: 'white',
	},
})