import React from 'react'
import { View, StyleSheet, Text } from 'react-native-web'

import { formatPricing } from '../helpers'


const f = formatPricing

export default function OrderDataRows({ orderItems }) {
	return (
		orderItems.map((order) => (
			<View style={styles.orderDataRow}>
				<Text style={styles.qty}>{` ${+order.item_qty}x `}</Text>
				<Text style={styles.itemName}>{order.item_name}</Text>
				<Text>{'   '}</Text>
				<Text style={styles.price}>{f(order.item_price * order.item_qty) + ' Ks'}</Text>
			</View>
		))
	)
}
const styles = StyleSheet.create({
	orderDataRow: {
		flexDirection: 'row',
		paddingTop: 10,
	},

	qty: {
		borderWidth: 1,
		alignSelf: 'flex-start',
		marginRight: 15,
		fontWeight: 'bold',
		borderRadius: 2,
	},
	itemName: {
		flex: 1,
		textAlign: 'left',
	},
	price: {
		textAlign: 'right',
		fontWeight: '500',
	},
})
