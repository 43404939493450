import React, { useContext, useEffect, useRef, useState } from 'react'

import useRequestLogin from '../apis/useRequestLogin'
import useFetchMasterRecord from '../apis/useFetchMasterRecord'

import Unavailable from './unavailable'
import Router from './router'
import useSnackbar from '../components/Snackbar'
import MyContext from '../components/MyContext'
import useCatchToken from '../hooks/useCatchToken'
import useCart from '../hooks/useCart'
import localeSetting from '../localeSetting'
import { setData } from '../helpers'

// Bug: App renders two times at start
export default function () {
	const dataRef = useRef()
	const setKey = (key, value) => setData(key, value, dataRef)
	const [token, lang, savedCart, tokenLoading] = useCatchToken(dataRef)
	const { mToken, login } = useRequestLogin()
	const { MySnackbar, showSnackbar } = useSnackbar()

	const tr = (string) => localeSetting(string)['en']

	const initialValue = {
		token, 
		mToken, 
		lang, 
		setKey, 
		showSnackbar, 
		tr, 

		savedCart, 
		tokenLoading, 
	}

	useEffect(() => {
		!mToken && login()
	}, [])

	return (
		<MyContext.Provider value={initialValue}>
			<p ref={dataRef} id={'data'} hidden />
			<MySnackbar />
			{mToken && token 
				? <App />
				: <Unavailable loading={tokenLoading} />}
		</MyContext.Provider>
	)
}

function App() {
	// Add more data (that is dependent on token and mToken) to Context 
	const { savedCart, tokenLoading, ...context } = useContext(MyContext)

	const { getMasterRecord, paymentOptions, profile } = useFetchMasterRecord()
	const cart = useCart()

	useEffect(() => {
		getMasterRecord()
	}, [])

	useEffect(() => {
		if (savedCart == {}) return
		cart.init(savedCart)
	}, [savedCart])

	const initialValue = {
		...context,
		cart,
		paymentOptions,
		profile,
	}

	return (
		<MyContext.Provider value={initialValue}>
			<Router />
		</MyContext.Provider>
	)
}