import React, { useEffect, useMemo } from 'react'
import { StyleSheet, View, Text, FlatList, RefreshControl, TouchableOpacity } from 'react-native-web'

import useFetchOrderList from '../apis/useFetchOrderList'

import OrderItem from '../components/OrderItem'
import Header from '../components/Header'
import constants from '../constants'


const { SCREEN_HEIGHT } = constants

export default function OrdersPage() {
	const { data, getOrderList, loading } = useFetchOrderList()

	useEffect(() => {
		getOrderList(true)
	}, [])

	const refreshPage = () => {
		getOrderList(true)
	}

	const loadMore = () => {
		getOrderList(false)
	}

	const renderItem = useMemo(() => ({ item, index }) => {
		const details = {
			orderNo: item.order_no, 
			orderItems: item.order_items, 
			paymentOption: item.payment_option, 
			totalPrice: item.total_price, 
			isPaid: item.is_paid, 
		}

		return (
			<OrderItem
				key={item.id}
				index={index}
				{...details}
			/>
		)
	}, [])

	return (
		<View style={styles.container}>
			<Header title={'Orders'}/>
			<FlatList
				style={styles.flatList}
				data={data}
				renderItem={renderItem}
				refreshControl={<RefreshControl onRefresh={refreshPage} refreshing={false} />}
				// ListFooterComponent={<Footer loading={loading} />}
				// ListEmptyComponent={<EmptyComponent loading={loading} />}
				// keyExtractor={extractKey}
				// onEndReachedThreshold={1}
				onEndReached={loadMore}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		height: SCREEN_HEIGHT,
		backgroundColor: '#eee',
		textAlign: 'center',
	},
	flatList: {

	},
})