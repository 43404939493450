import React, { useContext, useEffect, useState } from 'react'
import { StyleSheet, View, Text, TouchableOpacity, Keyboard } from 'react-native-web'
import Divider from '@mui/material/Divider'

import useFetchDeliFee from '../apis/useFetchDeliFee'

import Button from '../components/Button'
import Header from '../components/Header'
import TextRow from '../components/TextRow'
import InputLocation from '../components/InputLocation'
import InputPaymentOptions from '../components/InputPaymentOptions'
import CheckoutCart from '../components/CheckoutCart'
import MyContext from '../components/MyContext'
import { formatPricing } from '../helpers'
import constants from '../constants'
import useCheckout from '../hooks/useCheckout'
import { useNavigate } from 'react-router-dom'

const { SCREEN_HEIGHT, SCREEN_WIDTH, PRIMARY, PATH } = constants
const f = formatPricing


export default function CheckoutPage() {
	const { fee, getFee } = useFetchDeliFee()
	const { cart } = useContext(MyContext)
	const navigate = useNavigate()
	const checkout = useCheckout()

	const [loading, setLoading] = useState(false)
	const [feeLoading, setFeeLoading] = useState(false)
	const [city, setCity] = useState({})
	const [zone, setZone] = useState({})
	const [address, setAddress] = useState()
	const [method, setMethod] = useState()
	const [option, setOption] = useState()
	const [discount, setDiscount] = useState()

	const InputLocationComponent = InputLocation(city, setCity, zone, setZone, address, setAddress)
	const InputPayOptionsComponent = InputPaymentOptions(setMethod, setOption, setDiscount)
	const CartScrollView = CheckoutCart(discount)

	const link = PATH.SUCCESS
	const totalPrice = cart.getTotalPrice(discount)
	const finalPrice = totalPrice
	const readyToCheckout = fee && !loading && !feeLoading

	useEffect(() => {
		(async () => {
			setFeeLoading(true)
			const status = city.id && zone.id && await getFee(64, city.id, zone.id, cart.getTotalWeight())
			if (!status?.status) return
			setFeeLoading(false)
		})()
	}, [city, zone])

	const handleCheckout = async () => {
		const success = await checkout(city.id, zone.id, totalPrice, fee, method, option, setLoading)
		if (success) navigate(link)
	}

	return (
		<View style={styles.container}>
			<Header title={'Confirm Order'} />

			<View style={styles.body}>
				{InputLocationComponent}
				<View style={styles.gap} />
				{InputPayOptionsComponent}
				<Divider style={styles.divider}/>
				{CartScrollView}
			</View>

			<Divider style={styles.divider}/>
			<TextRow>
				<Text style={[styles.botTxt]}>Item Price</Text>
				<Text style={[styles.botTxt]}>{`${f(totalPrice)} Ks`}</Text>
			</TextRow>
			{!loading && <>
				<TextRow>
					<Text style={[styles.botTxt, styles.fee]}>Delivery Fee</Text>
					<Text style={[styles.botTxt, styles.fee]}>{`${f(fee)} Ks`}</Text>
				</TextRow>
				<Divider style={styles.divider}/>
				<TextRow>
					<Text style={[styles.botTxt]}>Total Price</Text>
					<Text style={[styles.botTxt, styles.primary]}>{`${f(finalPrice)} Ks`}</Text>
				</TextRow>
			</>}
			<Button title={'Order now'} onPress={handleCheckout} style={styles.botButton} 
			loading={loading || feeLoading} disabled={!readyToCheckout} />
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		height: SCREEN_HEIGHT,
		width: SCREEN_WIDTH,
		backgroundColor: '#ddd',
	},
	body: {
		flex: 1,
		padding: 20,
	},
	gap: {
		height: 5,
	},

	row: {
		flexDirection: 'row',
		paddingHorizontal: 20,
		paddingVertical: 2,
	},
	flex: {
		flex: 1,
	},

	botTxt: {
		paddingVertical: 2,
		fontSize: 16,
		fontWeight: '600',
	},
	fee: {
		textDecorationLine: 'line-through',
	},
	primary: {
		color: PRIMARY,
	},
	divider: {
		margin: 5
	},

	botButton: {
		marginVertical: 10,
		marginHorizontal: 20,
		paddingVertical: 10,
	},
	disabled: {
		backgroundColor: '#aaa',
	},
	white: {
		color: 'white',
	},
})