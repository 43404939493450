import React, { useState, useEffect, useMemo, useContext } from 'react'
import { StyleSheet, View, Text, ScrollView, FlatList } from 'react-native-web'

import { useNavigate } from 'react-router-dom'

import MainHeader from '../components/MainHeader'
import Carousel from '../components/Carousel'
import Category from '../components/Category'
import ProductItem from '../components/ProductItem'
import useFetchProductList from '../apis/useFetchProductList'

import constants from '../constants'
import MyContext from '../components/MyContext'

const { SCREEN_HEIGHT_NAVBAR, PATH } = constants

export default function HomePage() {
	const navigate = useNavigate()
	const { setKey } = useContext(MyContext)
	const { data, getProductList } = useFetchProductList()
	const { data: featured, getProductList:getFeatured } = useFetchProductList()
	const { data: discounted, getProductList:getDiscounted } = useFetchProductList()
	const { data: seasonal, getProductList:getSeasonal } = useFetchProductList()
	const [search, setSearch] = useState('')

	const filteredList = data.filter(item => item.item_name.toLowerCase()
		.includes(search.toLowerCase()))

	useEffect(() => {
		getProductList(true)
		getFeatured(true, {is_feature:true})
		getDiscounted(true, {is_discount:true})
		getSeasonal(true, {is_seasonal:true})

		setKey('home', true)
		return () => setKey('home', false)
	}, [])

	const goToFeatured = () => navigate(`${PATH.PRODUCTS}${'is_feature'}`)
	const goToDiscounted = () => navigate(`${PATH.PRODUCTS}${'is_discount'}`)
	const goToSeasonal = () => navigate(`${PATH.PRODUCTS}${'is_seasonal'}`)

	const refreshPage = async () => {
		await getProductList(true)
	}

	const loadMore = async () => {
		await getProductList()
	}

	const renderItem = useMemo(() => ({item, index}) => (
		<ProductItem key={item.id} item={item} index={index} /> 
	), [])

	return (
		<View style={styles.container}> 
			<MainHeader title={'Good P Market'} showSearch
			search={search} setSearch={setSearch} setKey={setKey} />
			<ScrollView>
				<View style={styles.margin} />
				{!search && <>
				<Category title={'Popular'} data={featured} style={styles.category} onPress={goToFeatured} />
				<Category title={'On sale'} data={discounted} style={styles.category} onPress={goToDiscounted} />
				<Category title={'Seasonal'} data={seasonal} style={styles.category} onPress={goToSeasonal} />
				</>}
				{data.length > 0 && <Text style={styles.title}>All items</Text>}
				<FlatList
					style={styles.flatList}
					data={filteredList}
					renderItem={renderItem}
					onEndReached={loadMore}
					numColumns={2}
					initialNumToRender={4}
				/>
			</ScrollView>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		height: SCREEN_HEIGHT_NAVBAR,
		backgroundColor: '#ddd',
	},
	margin: {
		margin: 6,
	},
	category: {
		backgroundColor: 'white',
		borderRadius: 10,
		marginHorizontal: 20,
		marginBottom: 10,
		padding: 10,
	},
	title: {
		marginHorizontal: '10%',
		marginTop: 10,
		marginBottom: 10,
	},
	flatList: {
		marginHorizontal: '8%',
	},
})