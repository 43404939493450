export function formatPricing(price) {
	return parseInt(price)
		.toString()
		.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function filtersToString(filters) {
	let strFilter = ''
	for (const filter in filters) {
		strFilter += `&${filter}=${filters[filter]}`
	}
	return strFilter
}

export function setData(key, value, ref) {
	try {
	const currentJson = JSON.parse(ref.current.innerHTML)
	currentJson[key] = value
	ref.current.innerHTML = JSON.stringify(currentJson)
	} catch (e) {
		// Do nothing if innerHTML is unreadable
		// Will crash if exception is not caught
	}
}
