import React, { useState, useContext } from 'react'

import MyContext from '../components/MyContext'

const MERCHANT_URL = process.env.REACT_APP_MERCHANT_URL
const paginate = 10

export default () => {
	const [nextLink, setNextLink] = useState(`${MERCHANT_URL}/orders?page=1`)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const { mToken, showSnackbar, profile } = useContext(MyContext)
	const agentId = profile.id

	const getOrderList = async (reset) => {
		if (nextLink == null && !reset) return
		setLoading(true)

		const abortController = new AbortController()

		const timeout = setTimeout(() => {
			showSnackbar('Connection timeout, please try again!', 'warning')
			abortController.abort()
			setLoading(false)

			return {status: false}
		}, 20000)

		try {
			const link = reset
				? `${MERCHANT_URL}/orders?page=1&paginate=${paginate}&good_agent_id=${agentId}`
				: `${nextLink}&paginate=${paginate}&good_agent_id=${agentId}`
			const response = await fetch(`${link}`, {
				signal: abortController.signal,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${mToken}`,
				},
			})

			if (response.status >= 400 && response.status <= 500) {
				showSnackbar('Please contact Marathon for this problem.', 'error')
				clearTimeout(timeout)
				setLoading(false)

				return {status: false}
			}

			const responseData = await response.json()
			// console.log("FetchOrderList:", responseData)  //debug

			if (responseData.status == 3) {
				alert('Session expired')
				clearTimeout(timeout)
				setLoading(false)

				return {status: false}
			}

			if (responseData.status == 2) {
				showSnackbar('Something went wrong.', 'warning')
				clearTimeout(timeout)
				setLoading(false)

				return {status: false}
			}

			if (responseData.status == 1) {
				clearTimeout(timeout)
				setLoading(false)

				if (reset) {
					setData(responseData.data)
				} else {
					setData(data.concat(responseData.data))
				}
				setNextLink(responseData.links.next)

				return {status: true}
			}
		} catch (e) {
			setLoading(false)
			showSnackbar('Connection problem, please try again.', 'warning')
			clearTimeout(timeout)
			console.log(e)

			return {status: false}
		}
	}

	return {
		loading,
		data,
		getOrderList,
	}
}
