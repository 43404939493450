import React from 'react'
import { StyleSheet, View, Text, Image, TouchableOpacity } from 'react-native-web'

import MyContext from '../components/MyContext'
import constants from '../constants'
import Header from '../components/Header'
import { formatPricing } from '../helpers'


const { SCREEN_HEIGHT, IMAGE } = constants

export default function ComingSoonPage() {
	return (
		<View style={styles.container}>
			<Header title={'Coming soon'}/>
			<View style={styles.body}>
				<View style={styles.imageWrapper}>
					<Image source={IMAGE.EMPTY_CART} resizeMode={'contain'} style={styles.image}/>
				</View>

				<Text>Coming soon</Text>
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		height: SCREEN_HEIGHT,
		backgroundColor: '#eee',
		textAlign: 'center',
	},
	body: {
		marginTop: 50,
	},
	imageWrapper: {
		alignItems: 'center',
	},
	image: {
		width: '50%',
		height: 250,
	},
})