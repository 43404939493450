import React, { useMemo } from 'react'
import { StyleSheet, Text, View, Image } from 'react-native-web'

import constants from '../constants'
import { formatPricing } from '../helpers'

const { PRIMARY } = constants
export default function CategoryItem({ item }) {
	const beforePrice = `${formatPricing(item.item_price)} Ks`
	const afterPrice = `${formatPricing(item.item_price - item.discount_amount)} Ks`
	const source = { uri: item.attachments[0].image_url }
	const isDiscount = beforePrice != afterPrice

	const render = useMemo(() => (
		<View style={styles.container}>
			<Image source={source} style={styles.image} resizeMode={'contain'} />
			{isDiscount && <Text style={styles.discount}>{beforePrice}</Text>}
			<Text style={styles.price}>{afterPrice}</Text>
		</View>
	), [])

	return render
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		textAlign: 'center',
		backgroundColor: 'white',
	},
	image: {
		alignSelf: 'center',
		height: 100,
		width: '100%',
	},
	discount: {
		fontSize: 10,
		fontWeight: '600',
		textDecorationLine: 'line-through',
	},
	price: {
		fontSize: 12,
		fontWeight: '600',
		color: PRIMARY,
		paddingHorizontal: 2,
	},
})