import React, { useContext, useEffect, useState } from 'react'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native-web'

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'

import MyContext from '../components/MyContext'
import constants from '../constants'

const { SCREEN_HEIGHT, SCREEN_WIDTH, PRIMARY } = constants

export default function InputPaymentOptions(setMethod, setOption, setDiscount) {
	const { paymentOptions } = useContext(MyContext)

	const [methodIndex, setMethodIndex] = useState(0)
	const [optionIndex, setOptionIndex] = useState(0)
	const [methods, setMethods] = useState([])

	const availableOptions = paymentOptions.filter(option => option.method == methods[methodIndex])

	useEffect(() => {
		let uniqueMethods = []
		for (let paymentOption of paymentOptions) {
			const uniqueMethod = paymentOption.method
			if (uniqueMethods.includes(uniqueMethod)) continue
			else uniqueMethods.push(uniqueMethod)
		}
		setMethods(uniqueMethods)
		setMethod(uniqueMethods[0])
		const option = paymentOptions.filter(option => option.method == uniqueMethods[0])[0]
		setOption(option.option)
		setDiscount(option.discount_percentage)
	}, [paymentOptions])

	const changeMethod = (index) => {
		setMethodIndex(index)
		setMethod(methods[index])
		setOptionIndex(0)
		const option = paymentOptions.filter(option => option.method == methods[index])[0]
		setOption(option.option)
		setDiscount(option.discount_percentage)
	}

	const changeOption = (index) => {
		setOptionIndex(index)
		setOption(availableOptions[index].option)
		setDiscount(availableOptions[index].discount_percentage)
	}
	
	return (
		<View>
			<View style={styles.methods}>
				{methods.map((method, index) => (
					<TouchableOpacity 
						key={index}
						onPress={() => changeMethod(index)} 
						style={[styles.method, index == methodIndex && {borderBottomWidth:1}]}
					>
						<Text style={index == methodIndex && {color:PRIMARY, fontWeight:'bold'}}>
							{method}
						</Text>
					</TouchableOpacity>
				))}
			</View>
			{availableOptions.map((option, index) => (
				<TouchableOpacity key={index} onPress={() => changeOption(index)} style={styles.option}>
					{index == optionIndex ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
					{option.discount_percentage
						? <Text>{`  ${option.option}  (${option.discount_percentage}% off)`}</Text>
						: <Text>{`  ${option.option}`}</Text>}
				</TouchableOpacity>
			))}
		</View>
	)
}


const styles = StyleSheet.create({
	container: {
		height: SCREEN_HEIGHT,
		width: SCREEN_WIDTH,
		backgroundColor: '#ddd',
	},
	body: {
		padding: 20,
	},
	methods: {
		flexDirection: 'row',
	},
	method: {
		flex: 1,
		textAlign: 'center',
		paddingTop: 15,
		paddingBottom: 10,
		borderColor: PRIMARY,
	},
	option: {
		flexDirection: 'row',
		alignItems: 'center',
		paddingTop: 15,
		color: PRIMARY
	},
})