import React, { useState, useContext, useEffect } from 'react'
import { StyleSheet, View, Text, Image, TouchableOpacity, ActivityIndicator } from 'react-native-web'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import MyContext from '../components/MyContext'
import constants from '../constants'
import Header from '../components/Header'
import { formatPricing } from '../helpers'

import useFetchProductList from '../apis/useFetchProductList'

const { SCREEN_HEIGHT, PRIMARY, PATH } = constants
const f = formatPricing

export default function DetailsPage() {
	const { getProductById } = useFetchProductList()

	const { id } = useParams()
	const navigate = useNavigate()
	const location = useLocation()
	const { cart } = useContext(MyContext)

	const [item, setItem] = useState(location.state?.item)
	const [qty, setQty] = useState("1")
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		(async () => {
			if (!!item) return
			const response = await getProductById(id)
			if (response.status) setItem(response.payload)
			setLoading(false)
		})()
	}, [])

	const changeQty = (newQty) => {
		if (parseInt(newQty) <= 0) return
		setQty(newQty)
	}

	const increment = () => changeQty(parseInt(qty) + 1)
	const decrement = () => changeQty(parseInt(qty) - 1)

	const handleBuy = () => {
		cart.add(item.id, qty)
		cart.addInfo(item.id, item)
		navigate(PATH.CART)
	}

	const handleCart = async () => {
		cart.add(item.id, qty)
		cart.addInfo(item.id, item)
		navigate(-1)
	}

	if (!item) return (
		<View style={styles.container}>
			<Header title={'Details'} showCart/>
			<View style={styles.noItem}>
			{loading
				? <ActivityIndicator />
				: <Text>Item does not exist</Text>}
			</View>
		</View>
	)

	const source = { uri: item.attachments[0].image_url }
	const name = item.item_name
	const price = parseInt(item.item_price)
	const discount = parseInt(item.discount_amount)
	const discounted_price = discount ? price - discount : price

	return (
		<View style={styles.container}>
			<Header title={'Details'} showCart/>

			<View style={styles.imageWrapper}>
				<Image source={source} resizeMode={'contain'} style={styles.image}/>
				<Text style={styles.name}>{name}</Text>
				{discount 
					? <><Text style={styles.strikethrough}>{f(price)} Ks</Text>
						<Text style={styles.price}>{f(discounted_price)} Ks</Text></>
					: <Text style={styles.price}>{f(price)} Ks</Text>}
			</View>

			<Text style={[styles.rowText, {paddingLeft: 25, paddingTop: 30}]}>Details :</Text>
			<View style={[styles.row, {borderBottomWidth: 0}]}>
				<Text style={[styles.rowText, styles.rowName]}>{name}</Text>
				<Text style={[styles.rowText, styles.rowPrice]}>{f(discounted_price)} Ks</Text>
			</View>

			<View style={styles.flex} />

			<View style={styles.row}>
				<Text style={[styles.rowText, styles.rowName]}>{name}</Text>
				<Text style={styles.rowText}>x {f(qty)}</Text>
				<Text style={styles.rowText}> = </Text>
				<Text style={[styles.rowText, styles.rowPrice]}>{f(discounted_price * qty)} Ks</Text>
			</View>

			<View style={styles.footer}>
				<ButtonHoc label={'-'} styleName={'plusButton'} color={'white'} onPress={decrement}/>
				<ButtonHoc label={` ${qty} `} styleName={'rowText'} />
				<ButtonHoc label={'+'} styleName={'plusButton'} color={'white'} onPress={increment} />
				<ButtonHoc label={'Buy'} styleName={'rectButton1'} color={PRIMARY} onPress={handleBuy} />
				<ButtonHoc label={'Add to Cart'} styleName={'rectButton2'} color={'white'} onPress={handleCart} />
			</View>
		</View>
	)
}

const ButtonHoc = ({ label, styleName, color, onPress }) => (
	<TouchableOpacity onPress={onPress} style={styles[styleName]}>
		<Text style={[styles.footerText, {color}]}>{label}</Text>
	</TouchableOpacity>
)

const styles = StyleSheet.create({
	noItem: {
		height: SCREEN_HEIGHT,
		justifyContent: 'center',
		alignItems: 'center',
	},
	container: {
		height: SCREEN_HEIGHT,
		backgroundColor: '#fff',
	},
	imageWrapper: {
		alignItems: 'center',
	},
	image: {
		width: '50%',
		height: 250,
	},
	name: {
		fontSize: 25,
		padding: 20,
		fontWeight: 'bold',
		textAlign: 'center',
	},
	strikethrough: {
		textDecorationLine: 'line-through',
		fontSize: 20,
	},
	price: {
		color: PRIMARY,
		fontSize: 30,
		fontWeight: '600',
	},
	flex: {flex: 1},

	row: {
		flexDirection: 'row',
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderBottomWidth: 1,
	},
	rowText: {
		fontSize: 16,
		fontWeight: '600',
		paddingHorizontal: 5,
	},
	rowName: {
		flex: 1,
	},
	rowPrice: {
		minWidth: '23vw',
		textAlign: 'right'
	},

	footer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingVertical: 10,
		paddingHorizontal: 20,
		marginVertical: 10,
	},
	plusButton: {
		backgroundColor: PRIMARY,
		height: 30,
		width: 30,
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 30,
	},
	rectButton1: {
		flex: 1,
		paddingVertical: 5,
		marginLeft: 10,
		backgroundColor: 'white',
		borderWidth: 2,
		borderRadius: 5,
		borderColor: PRIMARY,
	},
	rectButton2: {
		flex: 1,
		paddingVertical: 5,
		marginLeft: 10,
		backgroundColor: PRIMARY,
		borderWidth: 2,
		borderRadius: 5,
		borderColor: PRIMARY,
	},
	footerText: {
		fontSize: '4vw',
		fontWeight: '600',
		textAlign: 'center',
	},
})