const constants = {
	PRIMARY: "#67ae3f",

	NAVBAR_HEIGHT: "7vh",
	SCREEN_HEIGHT_NAVBAR: "93vh",
	SCREEN_HEIGHT: "100vh",
	SCREEN_WIDTH: "100vw",

	PATH: {
		HOME: "/home/",
		CART: "/cart/",
		CHECKOUT: "/cart/checkout/",
		SUCCESS: "/cart/checkout/success/",
		PROFILE: "/home/profile/",
		ORDERS: "/orders",
		PRODUCTS: "/products/",
		DETAILS: "/products/details/",
		COMING_SOON: "/coming_soon/",
		IS_HOME: "/home/*",
	},

	IMAGE: {
		EMPTY_CART: require("./assets/empty_cart.png"),
		COMPLETE: require("./assets/complete.png"),
		MKITCHEN: require("./assets/m_kitchen_logo.png"),
	},
}

export default constants