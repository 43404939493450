import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { StyleSheet, View, Text, Image, TouchableOpacity } from 'react-native-web'

import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import PlaceIcon from '@mui/icons-material/Place'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import MyContext from '../components/MyContext'
import constants from '../constants'
import MainHeader from '../components/MainHeader'

const { SCREEN_HEIGHT_NAVBAR, SCREEN_WIDTH, PRIMARY, PATH } = constants

export default function ProfilePage() {
	const { profile, setKey } = useContext(MyContext)
	const navigate = useNavigate()
	if (!profile) return <View style={styles.container}>
		<MainHeader title={'Profile'} setKey={setKey} />
	</View>

	const name = profile.name
	const source = {uri: profile.image}
	const phone = profile.phone
	const address = profile.address

	const goToOrders = () => {
		navigate(PATH.ORDERS)
	}

	return (
		<View style={styles.container}>
			<MainHeader title={'Profile'} setKey={setKey} />
			<View style={styles.body}>
				<Image source={source} style={styles.image} />
				<Text style={styles.title1}>{name}</Text>
				<Text style={styles.title2}>{phone}</Text>
				<Row onPress={goToOrders}
					c1={<LocalShippingIcon fontSize={'inherit'} />}
					c2={'Orders'}
					c3={<ChevronRightIcon fontSize={'inherit'} />}
				/>
				<Row 
					c1={<PlaceIcon fontSize={'inherit'} />}
					c2={address}
					c3={''}
				/>
			</View>
		</View>
	)
}

const Row = ({c1, c2, c3, onPress}) => {
	return (
		<TouchableOpacity style={styles.row} disabled={!onPress} onPress={onPress}>
			<Text style={[styles.rowTxt, styles.txt1]}>{c1}</Text>
			<Text style={[styles.rowTxt, styles.txt2]}>{c2}</Text>
			<Text style={[styles.rowTxt, styles.txt3]}>{c3}</Text>
		</TouchableOpacity>
	)
}

const styles = StyleSheet.create({
	container: {
		height: SCREEN_HEIGHT_NAVBAR,
		width: SCREEN_WIDTH,
		backgroundColor: '#ddd',
	},
	body: {
		flex: 1,
		alignItems: 'center',
		paddingTop: 100,
	},
	image: {
		width: 100,
		height: 100,
		borderRadius: 500,
	},
	title1: {
		marginTop: 10,
		fontSize: 16,
	},	
	title2: {
		fontSize: 12,
		marginBottom: 15,
	},
	row: {
		flexDirection: 'row',
		alignSelf: 'stretch',
		alignItems: 'flex-start',
		borderWidth: 1,
		borderColor: PRIMARY,
		borderRadius: 5,
		paddingVertical: 7,
		marginHorizontal: 20,
		marginVertical: 5,

	},
	rowTxt: {
		color: PRIMARY,
	},
	txt1: {
		paddingHorizontal: 16,
		marginBottom: -6,
		fontSize: 20,
	},
	txt2: {
		flex: 1,
		alignSelf: 'center',
		fontWeight: '600',
	},
	txt3: {
		paddingHorizontal: 10,
		marginBottom: -6,
		fontSize: 22,
		fontWeight: 'bold',
	},
})