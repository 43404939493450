import React, { useState, useEffect } from 'react'

export default function useCatchToken(dataRef) {
	const [tokenLoading, setTokenLoading] = useState(true)
	const [token, setToken] = useState()
	const [lang, setLang] = useState('mm')
	const [savedCart, setSavedCart] = useState({})
	const [refresh, setRefresh] = useState(true)

	const catchToken = () => {
		if (!!token) return
		
		let data

		try {data = JSON.parse(dataRef.current.innerHTML)} 
		catch (e) {return}
		if (data?.valid !== true) return

		setToken(data.token)
		setLang(data.lang)
		
		if (!!data.cart) {
			try {setSavedCart(JSON.parse(data.cart))} 
			catch (e) {return}
		}
	}

	useEffect(() => {
		catchToken()
		setTimeout(() => setTokenLoading(false), 2000)
	}, [dataRef, refresh])

	useEffect(() => {
		const interval = setInterval(() => {
			setRefresh(refresh => !refresh)
		}, 200)

		setTimeout(() => {
			clearInterval(interval)
			try {
				const original = JSON.parse(dataRef.current.innerHTML)
				delete original['token']
				delete original['valid']
				dataRef.current.innerHTML = JSON.stringify(original)
			} catch (e) {
				// No data sent - assume unauthorised source
			}
		}, 2000)

		return () => clearInterval(interval)
	}, [])

	return [token, lang, savedCart, tokenLoading]
}