import React, { useContext } from 'react'
import { StyleSheet, View, Text, ScrollView } from 'react-native-web'

import MyContext from './MyContext'
import { formatPricing } from '../helpers'
import constants from '../constants'

const { PRIMARY } = constants
const f = formatPricing


export default function CheckoutCart(discount) {
	const { cart } = useContext(MyContext)

	return (
		<View style={styles.container}>
			<ScrollView>
				{Object.keys(cart.data).map((id, index) => {
					const item = cart.info[id]
					const qty = cart.data[id]
					const name = item.item_name
					const preDiscountPrice = item.item_price - item.discount_amount
					const price = preDiscountPrice * (1 - discount/100)
					const preDiscountTotalPrice = preDiscountPrice * qty
					const totalPrice = price * qty
					return (
						<View style={styles.itemWrapper} key={index}>
							<Text style={styles.qty}>{qty}x</Text>
							{!discount
								? <Text style={styles.name}>{name} ({f(price)})</Text>
								: <Text style={styles.name}>{name} ({f(price)})</Text>}
							{!discount
								? <Text style={styles.price}>{f(totalPrice)} Ks</Text>
								: <Text style={styles.price}>{f(totalPrice)} Ks</Text>}
						</View>
					)
				})}
			</ScrollView>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#ddd',
		paddingHorizontal: 20,
	},
	itemWrapper: {
		flexDirection: 'row',
		alignItems: 'center',
		paddingVertical: 5,
	},
	qty: {
		borderWidth: 1,
		paddingVertical: 3,
		paddingHorizontal: 5,
		marginRight: 12,
		alignSelf: 'flex-start',
	},
	name: {
		flex: 1,
		color: PRIMARY,
	},
	price: {
		textAlign: 'right',
		paddingLeft: 10,
	},
})