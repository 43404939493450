import React from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native-web'

import CategoryItem from './CategoryItem'

export default function Category({title, data, onPress, style}) {
	const Items = data.map((item, index) => index < 4 &&
		<CategoryItem key={item.id} item={item} index={index} />)

	if (data.length == 0) return <></>
	return (
		<TouchableOpacity style={style} onPress={onPress}>
			<View style={styles.header}>
				<Text>   {title}</Text>
				<Text style={styles.details}>{`Details >`}</Text>
			</View>
			<View style={styles.row}>
				{Items}
			</View>
		</TouchableOpacity>
	)
} 

const styles = StyleSheet.create({
	header: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	row: {
		flexDirection: 'row',
	},
	details: {
		fontSize: 11,
	},
})