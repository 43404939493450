import React, { useState } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import HomePage from './home'
import CartPage from './cart'
import ProfilePage from './profile'
import OrdersPage from './orders'
import ProductsPage from './products'
import DetailsPage from './details'
import CheckoutPage from './checkout'
import SuccessPage from './success'
import ComingSoonPage from './coming_soon'
import BottomNav from '../components/BottomNav'

import constants from '../constants'

const { PATH } = constants


export default function Router() {
	const [route, setRoute] = useState(0)
	const Nav = () => <BottomNav route={route} setRoute={setRoute} />
	return (
		<BrowserRouter>
			<Routes>
				<Route path={PATH.HOME} element={<HomePage />} />
				<Route path={PATH.CART} element={<CartPage />} />
				<Route path={PATH.CHECKOUT} element={<CheckoutPage />} />
				<Route path={PATH.SUCCESS} element={<SuccessPage />} />
				<Route path={PATH.PROFILE} element={<ProfilePage />} />
				<Route path={PATH.ORDERS} element={<OrdersPage />} />
				<Route path={`${PATH.PRODUCTS}:category`} element={<ProductsPage />} />
				<Route path={`${PATH.DETAILS}:id`} element={<DetailsPage />} />
				<Route path="*" element={<Navigate to={PATH.HOME} />} />
				<Route path={PATH.COMING_SOON} element={<ComingSoonPage />} />
			</Routes>
			<Routes>
				<Route path={PATH.IS_HOME} element={<Nav />} />
				<Route path="*" element={<></>} />
			</Routes>
		</BrowserRouter>
	)
}