import React from 'react'
import { StyleSheet, Text, TouchableOpacity, ActivityIndicator } from 'react-native-web'

import constants from '../constants'


const { PRIMARY } = constants

export default function Button({
	style, title, loading=false, disabled=loading, onPress
}) {
	return (
		<TouchableOpacity style={[styles.wrapper, disabled && styles.loading, style]} onPress={onPress}>
			{loading
				? <ActivityIndicator />
				: <Text style={styles.white}>{title}</Text>}
		</TouchableOpacity>
	)
}

const styles = StyleSheet.create({
	wrapper: {
		backgroundColor: PRIMARY,
		borderRadius: 10,
		height: 40,
		alignItems: 'center',
		justifyContent: 'center',
	},
	loading: {
		backgroundColor: '#aaa',
	},
	white: {
		color: 'white',
	},
})