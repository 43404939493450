import React, { useContext, useState } from 'react'
import { StyleSheet, View, Text } from 'react-native-web'

import useFetchProductList from '../apis/useFetchProductList'

import MyContext from '../components/MyContext'

const clearNegatives = (cart) => {
	for (let id in cart) {
		if (cart[id] <= 0) {
			delete cart[id]
		}
	}
}

export default function useCart() {
	const { getProductById } = useFetchProductList()
	const { setKey, showSnackbar } = useContext(MyContext)
	const [cart, setCart] = useState({})
	const [info, setInfo] = useState({})
	const data = cart

	const init = async (savedCart) => {
		setKey('cart', savedCart)
		const idList = Object.keys(savedCart)
		const infoPromises = await Promise.all(idList.map(id => getProductById(id)))
		for (let i in idList) {
			const id = idList[i]
			const info = infoPromises[i].payload
			if (infoPromises[i].status == false) return false
			addInfo(id, info)
		}
		setCart(savedCart)
		return true
	}

	const isReady = () => {
		const idList = getIdList()
		const infoIdList = Object.keys(info)
		const isIdReady = (id, index) => idList[index] == infoIdList[index]
		return idList.every(isIdReady)
	}

	const add = (id, qty) => {
		const newCart = cart
		if (!newCart[id]) newCart[id] = 0
		newCart[id] += parseInt(qty)
		setCart(newCart)
		setKey('cart', newCart)
		return true
	}

	const remove = (id, qty=10000) => {
		const newCart = cart
		if (!newCart[id]) return false
		newCart[id] -= parseInt(qty)
		clearNegatives(newCart)
		setCart(newCart)
		setKey('cart', newCart)
		return true
	}

	const addInfo = (id, itemInfo) => {
		const newInfo = info
		newInfo[id] = itemInfo
		setInfo(newInfo)
		return true
	}

	const clear = () => {
		setCart({})
		setKey('cart', {})
	}

	const getTotalQty = () => {
		let totalQty = 0
		for (let id in cart) {
			totalQty += cart[id]
		}
		return totalQty
	}

	const getTotalPrice = (discount) => {
		let totalPrice = 0
		for (let id in cart) {
			const qty = cart[id]
			const price = info[id].item_price - info[id].discount_amount
			totalPrice += qty * price
		}
		if (typeof discount == 'number') totalPrice *= 1 - (discount/100)
		return totalPrice
	}

	const getTotalWeight = () => {
		let totalWeight = 0
		for (let id in cart) {
			const qty = cart[id]
			const weight = info[id].weight 
			totalWeight += qty * weight
		}
		return totalWeight
	}

	const getOrderItems = () => {
		// get array of orderItems to post order in checkout page
		let orderItems = []
		for (let id in cart) {
			const product = info[id]
			orderItems.push({
				product_id: product.id,
				name: product.item_name,
				qty: cart[id],
				price: product.item_price - (product.discount_amount ?? 0),
				weight: +product.weight,
			})
		}
		return orderItems
	}

	const wrap = (component, m1, m2) => (
		<>
			{component}
			{getTotalQty() > 0 && <View style={[styles.wrap, {marginTop: m1, marginRight: m2}]}>
				<Text style={styles.txt}>{getTotalQty()}</Text>
			</View>}
		</>
	)

	const getIdList = () => {
		return Object.keys(cart)
	}

	return {
		data,
		info,
		init,
		isReady,
		add,
		addInfo,
		remove,
		getTotalQty,
		getTotalPrice,
		getTotalWeight,
		getOrderItems,
		wrap,
		getIdList,
		clear,
	}
}

const styles = StyleSheet.create({
	wrap: {
		position: 'absolute',
		top: 0,
		right: 0,
		backgroundColor: 'red',
		borderRadius: 100,
		width: 20,
		height: 20,
		alignItems: 'center',
		justifyContent: 'center',
	},
	txt: {
		color: 'white',
		fontSize: 12,
	},
})