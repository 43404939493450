import React, { useEffect, useMemo } from 'react'
import { StyleSheet, View, Text, ScrollView, FlatList } from 'react-native-web'

import { useNavigate, useParams } from 'react-router-dom'

import Header from '../components/Header'
import Carousel from '../components/Carousel'
import ProductItem from '../components/ProductItem'
import useFetchProductList from '../apis/useFetchProductList'

import constants from '../constants'


const { SCREEN_HEIGHT, PATH } = constants

export default function ProductsPage() {
	const navigate = useNavigate()
	const { category } = useParams()
	const { data, getProductList } = useFetchProductList()

	let filters = {}
	filters[category] = true

	useEffect(() => {
		getProductList(true, filters)
	}, [])

	const refreshPage = async () => {
		await getProductList(true, filters)
	}

	const loadMore = async () => {
		await getProductList(false, filters)
	}

	const renderItem = useMemo(() => ({item, index}) => (
		<ProductItem key={item.id} item={item} index={index} /> 
	), [])

	return (
		<View style={styles.container}> 
			<Header title={'Details'} showCart/>
			<ScrollView>
				<View style={styles.margin} />
				{data.length > 0 && <Text style={styles.title}>All items</Text>}
				<FlatList
					style={styles.flatList}
					data={data}
					renderItem={renderItem}
					onEndReached={loadMore}
					numColumns={2}
					initialNumToRender={4}
				/>
			</ScrollView>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		height: SCREEN_HEIGHT,
		backgroundColor: '#ddd',
	},
	margin: {
		margin: 6,
	},
	category: {
		backgroundColor: 'white',
		borderRadius: 10,
		marginHorizontal: 20,
		marginBottom: 10,
		padding: 10,
	},
	title: {
		marginHorizontal: '10%',
		marginTop: 10,
		marginBottom: 10,
	},
	flatList: {
		marginHorizontal: '8%',
	},
})