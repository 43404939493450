const data = {
	// BottomNav
	Home: {
		en: 'Home',
		mm: 'ပင်မ',
	},
	Store: {
		en: 'Store',
		mm: 'စတိုး',
	},
	Cart: {
		en: 'Cart',
		mm: 'ဈေးခြင်း',
	},
	Profile: {
		en: 'Profile',
		mm: 'ပရိုဖိုင်',
	},
}

export default function (key) {
	return data[key] ? data[key] : { en: key, mm: key }
}
