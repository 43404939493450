import React, { useState, useContext } from 'react'

import MyContext from '../components/MyContext'

const MERCHANT_URL = process.env.REACT_APP_MERCHANT_URL

export default () => {
	const [fee, setFee] = useState()
	const [loading, setLoading] = useState()
	const { mToken, showSnackbar } = useContext(MyContext)

	const getFee = async (from_city_id, to_city_id, to_zone_id, weight) => {
		setLoading(true)

		const abortController = new AbortController()

		const timeout = setTimeout(() => {
			showSnackbar('Connection timeout, please try again!', 'warning')
			abortController.abort()
			setLoading(false)

			return {status: false}
		}, 5000)

		try {
			const access_token = mToken
			const response = await fetch(`${MERCHANT_URL}/calculate_amount`, {
				signal: abortController.signal,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${access_token}`,
				},
				body: JSON.stringify({
					from_city_id,
					to_city_id,
					to_zone_id,
					global_scale_id: 1,
					weight,
				}),
			})

			if (response.status >= 400 && response.status <= 500) {
				showSnackbar('Please contact Marathon for this problem.', 'error')
				clearTimeout(timeout)
				setLoading(false)

				return {status: false}
			}

			const responseData = await response.json()
			// console.log("DeliveryFeeHook:", responseData)  //debug

			if (responseData.status == 3) {
				alert('Session expired')
				clearTimeout(timeout)
				setLoading(false)

				return {status: false}
			}

			if (responseData.status == 2) {
				showSnackbar('Something went wrong.', 'warning')
				clearTimeout(timeout)
				setLoading(false)

				return {status: false}
			}

			if (responseData.status == 1) {
				clearTimeout(timeout)
				setLoading(false)

				setFee(responseData.amount.door_to_door_rate)

				return {status: true, payload: responseData.amount.door_to_door_rate}
			}
		} catch (e) {
			setLoading(false)
			showSnackbar('Connection problem, please try again.', 'warning')
			clearTimeout(timeout)
			console.log(e)
			
			setFee()

			return {status: false}
		}
	}

	return {
		loading,
		fee,
		getFee,
	}
}
