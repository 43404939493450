import React, { useContext, useEffect } from 'react'
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native-web'

import CloseIcon from '@mui/icons-material/Close';

import MyContext from '../components/MyContext';

export default function Unavailable({ loading }) {
	const { setKey } = useContext(MyContext)

	useEffect(() => {
		setKey('home', true)
		return () => setKey('home', false)
	}, [])

	if (loading) return (
		<View style={styles.container}>
			<ActivityIndicator size={'small'} />
		</View>
	)
	else return (
		<View style={styles.container}>
			<CloseIcon style={styles.close} />
			<Text>No connection</Text>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		height: '100vh',
		alignItems: 'center',
		justifyContent: 'center',
	},
	close: {
		color: 'red',
		fontSize: 50,
	},
})