import React, { useState, useContext } from 'react'

import MyContext from '../components/MyContext'

const GOODP_URL = process.env.REACT_APP_GOODP_URL

export default () => {
	const [loading, setLoading] = useState(false)
	const [orderDetails, setOrderDetails] = useState()
	const { mToken, showSnackbar } = useContext(MyContext)

	const completeOrderTransaction = async (
		amount,
		note,
		payment_method,
		payment_option,
		order_id,

		option
	) => {
		setLoading(true)

		const abortController = new AbortController()

		const timeout = setTimeout(() => {
			showSnackbar('Connection timeout, please try again!', 'warning')
			abortController.abort()
			setLoading(false)

			return {status: false}
		}, 20000)

		try {
			const response = await fetch(`${GOODP_URL}/${option}`, {
				signal: abortController.signal,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${mToken}`,
					Accept: 'application/json',
				},
				body: JSON.stringify({
					amount,
					note,
					payment_method,
					payment_option,
					order_id,
				}),
			})

			if (response.status >= 400 && response.status <= 500) {
				showSnackbar('Please contact Marathon for this problem.', 'error')
				clearTimeout(timeout)
				setLoading(false)

				return {status: false}
			}

			const responseData = await response.json()
			//   console.log(responseData)

			if (responseData.status == 3) {
				alert('Session expired')
				clearTimeout(timeout)
				setLoading(false)

				return {status: false}
			}

			if (responseData.status == 2) {
				const message = responseData?.message
				if (!!message && typeof message == 'string') {
					showSnackbar(message, 'warning')
				} else {
					showSnackbar('Something went wrong.', 'warning')
				}
				clearTimeout(timeout)
				setLoading(false)

				return {status: false}
			}

			if (responseData.status == 1) {
				setOrderDetails(responseData.data)

				clearTimeout(timeout)
				setLoading(false)
				return {status: true, payload: responseData.data}
			}
		} catch (e) {
			setLoading(false)
			showSnackbar('Connection problem, please try again.', 'warning')
			clearTimeout(timeout)
			console.log(e)

			return {status: false}
		}
	}

	const makePrepaidOrder = (amount, note, payment_method, payment_option, order_id) =>
		completeOrderTransaction(amount, note, payment_method, payment_option, order_id, 'order')

	const makePostpaidOrder = (amount, note, payment_method, payment_option, order_id) =>
		completeOrderTransaction(amount, note, payment_method, payment_option, order_id, 'received_order')

	return {
		orderDetails,
		makePrepaidOrder,
		makePostpaidOrder,
		loading,
	}
}
