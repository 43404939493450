import React, { useContext } from 'react'

import usePostCheckout from '../apis/usePostCheckout'
import usePostOrderTransaction from '../apis/usePostOrderTransaction'

import MyContext from '../components/MyContext'


export default function useCheckout() {
	const { cart, profile, showSnackbar } = useContext(MyContext)
	const { makeOrder } = usePostCheckout()
	const { makePrepaidOrder } = usePostOrderTransaction()

	const checkout = async (
		receiver_city_id, 
		receiver_zone_id, 
		total_price,
		total_delivery_amount,
		payment_method,
		payment_option,
		setLoading, 
	) => {
		const totalPrice = +total_price

		if (
			+profile.account.balance < totalPrice && 
			payment_method == 'Pre-Paid' &&
			payment_option == 'GoodBalance'
		) {
			showSnackbar('Insufficient GoodBalance', 'warning')
			return false
		}

		setLoading(true)
		const { id, name, phone, address, email } = profile

		let orderSuccess = false
		let prepaidSuccess = false

		const order = await makeOrder({
			good_agent_id: id,
			receiver_name: name,
			receiver_phone: phone,
			receiver_address: address,
			receiver_email: email,
			receiver_city_id,
			receiver_zone_id,
			sender_city_id: 64,
			sender_zone_id: null,
			total_weight: cart.getTotalWeight(),
			total_qty: cart.getTotalQty(),
			total_price,
			total_delivery_amount,
			payment_method,
			payment_option,
			remark: '',
			order_items: cart.getOrderItems()
		})

		orderSuccess = !!order.status

		const orderId = order.payload.id
		if (payment_method == 'Pre-Paid' && orderSuccess) {
			prepaidSuccess = (await makePrepaidOrder(
				totalPrice, 
				'', 
				payment_method, 
				payment_option, 
				orderId
			)).status
		}

		if ((payment_method != 'Pre-Paid' && orderSuccess) || (orderSuccess && prepaidSuccess)) {
			cart.clear()
			return true
		} else {
			setLoading(false)
			showSnackbar('Error, please try again')
			return false
		}
	}

	return checkout
}