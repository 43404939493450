import React, { useState, useContext } from 'react'

import MyContext from '../components/MyContext'

const GOODP_URL = process.env.REACT_APP_GOODP_URL

export default () => {
	const [paymentOptions, setPaymentOptions] = useState()
	const [profile, setProfile] = useState()
	const [nrcs, setNrcs] = useState()
	const [loading, setLoading] = useState(false)
	const { token, showSnackbar } = useContext(MyContext)

	const getMasterRecord = async () => {
		setLoading(true)

		const abortController = new AbortController()

		const timeout = setTimeout(() => {
			showSnackbar('Connection timeout, please try again!', 'warning')
			abortController.abort()
			setLoading(false)

			return {status: false}
		}, 20000)

		try {
			const response = await fetch(`${GOODP_URL}/get_master_records`, {
				signal: abortController.signal,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			})

			if (response.status >= 400 && response.status <= 500) {
				showSnackbar('Please contact Marathon for this problem.', 'error')
				clearTimeout(timeout)
				setLoading(false)

				return {status: false}
			}

			const responseData = await response.json()
			// console.log("useMasterRecord:", responseData)  //debug

			if (responseData.status == 3) {
				alert('Session expired')
				clearTimeout(timeout)
				setLoading(false)

				return {status: false}
			}

			if (responseData.status == 2) {
				showSnackbar('Something went wrong.', 'warning')
				clearTimeout(timeout)
				setLoading(false)

				return {status: false}
			}

			if (responseData.status == 1) {
				setPaymentOptions(responseData.data.payment_options)
				setProfile(responseData.data.profile)
				setNrcs(responseData.data.nrc)

				clearTimeout(timeout)
				setLoading(false)
				return {status: true, payload: responseData.data}
			}
		} catch (e) {
			setLoading(false)
			showSnackbar('Connection problem, please try again.', 'warning')
			alert(e)
			clearTimeout(timeout)
			console.log(e)

			return {status: false}
		}
	}

	return {
		loading,
		getMasterRecord,
		paymentOptions,
		profile,
		nrcs,
	}
}
