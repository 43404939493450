import React, { useState, useContext } from 'react'

import MyContext from '../components/MyContext'

const MERCHANT_URL = process.env.REACT_APP_MERCHANT_URL

export default () => {
	const [loading, setLoading] = useState(false)
	const [order, setOrder] = useState()
	const { mToken, showSnackbar } = useContext(MyContext)

	const makeOrder = async ({
		good_agent_id,
		receiver_name,
		receiver_phone,
		receiver_address,
		receiver_email,
		receiver_city_id,
		receiver_zone_id,
		sender_city_id,
		sender_zone_id,
		total_weight,
		total_qty,
		total_price,
		total_delivery_amount,
		payment_method,
		payment_option,
		remark,
		order_items
	}) => {
		setLoading(true)

		const abortController = new AbortController()

		const timeout = setTimeout(() => {
			showSnackbar('Connection timeout, please try again!', 'warning')
			abortController.abort()
			setLoading(false)

			return {status: false}
		}, 20000)

		try {
			const response = await fetch(`${MERCHANT_URL}/orders`, {
				signal: abortController.signal,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${mToken}`,
					Accept: 'application/json',
				},
				body: JSON.stringify({
					good_agent_id,
					receiver_name,
					receiver_phone,
					receiver_address,
					receiver_email,
					receiver_city_id,
					receiver_zone_id,
					sender_city_id,
					sender_zone_id,
					total_weight,
					total_qty,
					total_price,
					total_delivery_amount,
					payment_method,
					payment_option,
					remark,
					thirdparty_invoice: null,
					global_scale_id: 1,
					order_items,
				}),
			})

			if (response.status >= 400 && response.status <= 500) {
				showSnackbar('Please contact Marathon for this problem.', 'error')
				clearTimeout(timeout)
				setLoading(false)

				return {status: false}
			}

			const responseData = await response.json()
			// console.log("MerchantCheckoutHook:", responseData)  //debug

			if (responseData.status == 3) {
				alert('Session expired (3)')
				clearTimeout(timeout)
				setLoading(false)

				return {status: false}
			}

			if (responseData.status == 2) {
				showSnackbar('Something went wrong.', 'warning')
				clearTimeout(timeout)
				setLoading(false)

				return {status: false}
			}

			if (responseData.status == 1) {
				clearTimeout(timeout)
				setLoading(false)

				setOrder(responseData.data)

				return {status: true, payload: responseData.data}
			}
		} catch (e) {
			setLoading(false)
			showSnackbar('Connection problem, please try again.', 'warning')
			clearTimeout(timeout)
			console.log(e)

			return {status: false}
		}
	}

	return {
		loading,
		order,
		makeOrder,
	}
}
