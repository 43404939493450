import React, { useState } from 'react'

import { Snackbar, Alert } from '@mui/material'

const MuiSnackbar = Snackbar

export default function useSnackbar() {
	const [open, setOpen] = useState(false)
	const [title, setTitle] = useState()
	const [severity, setSeverity] = useState('success')

	const MySnackbar = () => (
		<MuiSnackbar open={open} autoHideDuration={5000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
			<Alert onClose={() => setOpen(false)} severity={severity} sx={{ width: '100%' }}>
				{title}
			</Alert>
		</MuiSnackbar>
	)

	const showSnackbar = (title, severity) => {
		if (!severity) severity = 'success'
		setOpen(true)
		setTitle(title)
		setSeverity(severity)
	}

	return {
		MySnackbar, 
		showSnackbar,
	}
}