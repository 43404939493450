import React, { useState } from 'react'
import { View, StyleSheet, Text, Image, TouchableOpacity } from 'react-native-web'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import OrderDataRows from './OrderItemDataRow'
import constants from '../constants'
import { formatPricing } from '../helpers'


const f = formatPricing
const { IMAGE } = constants

export default function OrderItem(props) {
	const { orderNo, orderItems, paymentOption, 
		totalPrice, isPaid } = props
	const [isHidden, setIsHidden] = useState(true)

	const toggle = () => {
		setIsHidden(isHidden => !isHidden)
	}

	const Header = () => (
		<View style={styles.header}>
			<Text style={styles.orderNo}>{` #${orderNo} `}</Text>
	 	</View>
	)

	const MainBody = () => (
		<View style={styles.body}>
			<View>
				<Image source={IMAGE.MKITCHEN} style={styles.image} resizeMode={'contain'} />
				<Text style={styles.b}>
					{'M Kitchen'}
				</Text>
			</View>
			<View style={styles.right}>
				<Text style={[styles.b, styles.i]}>
					{!!isHidden && `Total: ${f(totalPrice)} Ks`}
				</Text>
				{isHidden ? <ExpandMoreIcon /> : <ExpandLessIcon />}
			</View>
		</View>
	)

	const ExtendedBody = () => (
		<View>
			<OrderDataRows orderItems={orderItems} />
			<View style={styles.bar} />
			<View style={styles.totalPrice}>
				<Text style={styles.b}>{'Total: '}</Text>
				<Text style={styles.b}>{f(totalPrice) + ' Ks'}</Text>
			</View>
			{/* <PayButton order={item} payLoading={props.payLoading} onPress={payOrder} /> */}
		</View>
	)

	return (
		<View style={styles.itemWrapper}>
	 		<Header />

	 		<TouchableOpacity style={styles.item} onPress={toggle}>
	 			<MainBody />
				{!isHidden && <ExtendedBody />}
	 		</TouchableOpacity>

		</View>
	)

}

const styles = StyleSheet.create({
	itemWrapper: {
		padding: 10,
		paddingBottom: 10,
	},
	header: {
		alignItems: 'flex-start',
		marginBottom: 5,
	},
	orderNo: {
		backgroundColor: '#ddd',
		borderRadius: 5,
	},
	seeDetails: {
		fontSize: 12,
		color: '#666'
	},

	item: {
		backgroundColor: 'rgb(248,248,248)',
		backgroundColor: 'white',
		borderWidth: 1,
		borderColor: '#ddd',
		paddingHorizontal: 20,
		paddingBottom: 10,
		borderRadius: 10,
	},
	body: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderRadius: 5,
		paddingVertical: 10,
	},
	right: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		minWidth: '50%',
		alignItems: 'center',
		marginTop: 10,
	},
	image: {
		width: 70,
		height: 70,
	},
	b: {
		fontWeight: 'bold',
	},
	i: {
		fontStyle: 'italic',
	},

	bar: {
		borderBottomWidth: 1,
		borderColor: '#999',
		marginVertical: 10,
		width: '100%',
	},
	totalPrice: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
})
